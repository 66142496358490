import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';

interface IHeadline {
    locationTitle: string
}

export const Headline: FC<IHeadline> = ({ locationTitle }) => {
    return <Text variant='h4' component='h1' bold>
        <>Novostavby{locationTitle && <> <br/>{locationTitle}</>}</>
    </Text>;
};
