import { EUnitPriceId } from 'components/page/search/map/enum/EUnitPriceId';
import { EUnitPriceName } from 'components/page/search/map/enum/EUnitPriceName';

export const unitPriceIdToUnitPriceNameMapper: Record<EUnitPriceId, EUnitPriceName> = {
    [EUnitPriceId.EUR]: EUnitPriceName.EUR,
    [EUnitPriceId.EUR_M2]: EUnitPriceName.EUR_M2,
    [EUnitPriceId.EUR_MONTH]: EUnitPriceName.EUR_MONTH,
    [EUnitPriceId.EUR_YEAR]: EUnitPriceName.EUR_YEAR,
    [EUnitPriceId.EUR_M2_MONTH]: EUnitPriceName.EUR_M2_MONTH,
    [EUnitPriceId.EUR_M2_YEAR]: EUnitPriceName.EUR_M2_YEAR,
    [EUnitPriceId.EUR_DAY]: EUnitPriceName.EUR_DAY,
};

