import { FC } from 'react';
import { Text } from 'modules/theme/components/text/Text';


interface INumericalPrice {
    text: string;
}

export const TextPrice: FC<INumericalPrice> = ({ text }) => {
    return <Text variant='body1' bold inverseColor>{text}</Text>;
};
