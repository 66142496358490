import { useCallback } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { SEARCH_MAP, SEARCH_MAP_ID } from 'modules/route/routes';
import useFilter from 'modules/stores/page/search/useFilter';
import locations from 'components/page/search/map/config/locations';
import { IFormValues } from 'components/page/search/map/interfaces/IFormValues';
import useMap, { DEFAULT_LATITUDE, DEFAULT_LONGITUDE, DEFAULT_ZOOM } from 'modules/stores/page/search/useMap';

interface IReturn {
    onFormValuesChange: (formValues: IFormValues) => void
}

export const useForm = (): IReturn => {
    const router = useRouter();
    const pathname = usePathname();
    const { setData: setMapData } = useMap();
    const { filter, setFilter } = useFilter();

    const onFormValuesChange = useCallback(({
        title,
        category,
        price_to,
        price_from,
        allProjects,
        transaction,
        location_id,
        real_estate_state,
    }) => {

        setFilter({
            ...filter,
            allProjects: !allProjects,
            fulltext: title || undefined,
            category: category || undefined,
            locationId: location_id || undefined,
            state: real_estate_state || undefined,
            transaction: transaction || undefined,
            price: { from: price_from || undefined, to: price_to || undefined },
        });

        const location = locations.find(location => location.id === location_id);
        const newRoute = location ? SEARCH_MAP_ID(location.sef) : SEARCH_MAP;

        if (pathname !== newRoute) {
            router.push(newRoute);
        }

        const mapData = {
            zoom: location ? location.zoom : DEFAULT_ZOOM,
            latitude: location ? location.geoPoint.lat : DEFAULT_LATITUDE,
            longitude: location ? location.geoPoint.lon : DEFAULT_LONGITUDE,
        };

        setMapData(mapData);
    }, [pathname, filter, router, setFilter, setMapData]);

    return {
        onFormValuesChange
    };
};

