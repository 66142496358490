import React, { FC } from 'react';
import { Box } from '@mui/material';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import { Text } from 'modules/theme/components/text/Text';


interface ISummaryInfo {
    categoryName: string;
    advertisementCount: number;
}

export const SummaryInfo: FC<ISummaryInfo> = ({ categoryName, advertisementCount }) => {
    return <Text variant='label2' noWrap inverseColor>
        <Box display='flex' gap={.5}>
            <HomeOutlined sx={{ width: 13, height: 13 }}/>
            <Box>{categoryName}</Box>
            <Box>•</Box>
            <Box>Počet inzerátov {advertisementCount}</Box>
        </Box>
    </Text>;
};

