import { FC } from 'react';
import { Grid } from '@mui/material';
import filterStateValues from 'components/page/search/map/helpers/filter/filterStateValues';
import filterCategoryValues from 'components/page/search/map/helpers/filter/filterCategoryValues';
import { ALL_PROJECTS_FIELD_NAME } from 'components/page/search/map/constantns/allProjectsFiledName';

import { Select } from 'components/page/search/map/components/filter/common/fields/Select';
import { Checkbox } from 'components/page/search/map/components/filter/common/fields/Checkbox';
import { TextInput } from 'components/page/search/map/components/filter/common/fields/TextInput';
import { Autocomplete } from 'components/page/search/map/components/filter/common/fields/Autocomplete';
import { locationSeoMethods } from 'components/page/search/map/helpers/locationSeoMethods';

export const Filter: FC = () => {
    const { getLocationOptions } = locationSeoMethods();

    return <Grid container spacing={1} alignItems='center' px={2} py={1}>

        <Grid item xs={12} md={4} lg={2} display='flex'>
            <TextInput fieldName='title' label='Napíšte čo hľadáte'  testId='title' />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
                fieldName='location_id'
                label='Zadajte okres alebo kraj'
                options={getLocationOptions}
                testId='location'
            />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select fieldName='category' filterValues={filterCategoryValues} testId='category' />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
            <Select fieldName='real_estate_state' filterValues={filterStateValues} testId='realEstateState' />
        </Grid>

        <Grid item container spacing={2} xs={12} sm={12} md={4} lg={3} xl={2}>

            <Grid item xs={6}>
                <TextInput fieldName='price_from' type='number' label='Cena od' testId='priceFrom' />
            </Grid>

            <Grid item xs={6}>
                <TextInput fieldName='price_to' type='number' label='Cena do' testId='priceTo' />
            </Grid>

        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={1} xl={2}>
            <Checkbox fieldName={ALL_PROJECTS_FIELD_NAME} label='Len exkluzívne projekty' testId='luxuryProject' />
        </Grid>

    </Grid>;
};
