import { FC } from 'react';
import { Box } from '@mui/material';
import Field from '@uc-platform/advertisement-search-form/dist/es5/Components/Fields/Types/Field';
import useFieldContext from '@uc-platform/advertisement-search-form/dist/es5/Components/Hooks/useFieldContext';
import { CheckboxWithLabel as DesignCheckbox } from 'modules/theme/components/fields/checkbox/CheckboxWithLabel';

interface IProps {
    label: string;
    fieldName: string;
    testId?: string;
}

export const Checkbox: FC<IProps> = ({ fieldName, label, testId }) => {
    const { value, setValue } = useFieldContext(fieldName, Field.OPERATION_EQUAL);
    
    return (
        <Box component='span' data-test-id={testId}>
            <DesignCheckbox
                label={label}
                isChecked={!!value}
                onClick={isChecked => setValue(isChecked)}
            />
        </Box>
    );
};
