import locations, { ILocation } from 'components/page/search/map/config/locations';
import { getRegionMeta } from 'components/page/search/map/helpers/locationSeoHelper';
import { IOption } from 'modules/theme/components/fields/select/interfaces/IOption';
import { AppConfigService } from 'modules/appConfig/AppConfigService';

interface IUseLocations {
    getLocationOptions: IOption[],
    getLocationHeaderTitle: () => string,
    getLocationHeadMetaTitle: () => string,
    getLocationHeadMetaDesc: () => string,
}

export const locationSeoMethods = (selectedLocation?: ILocation): IUseLocations => {

    const getLocationOptions = locations.map(location => ({
        value: location.id,
        name: location.name,
        isParent: location.isMain
    }));

    const getEditedRegionName = (selLocation: string, searchValue: string, replaceValue: string) => {
        if (selLocation.includes(searchValue)) {
            return selLocation.replace(searchValue, replaceValue);
        }

        return selLocation;
    };

    const getSelectedLocationNameForMetaTitle = (selectedLocation) => {
        if (selectedLocation) {
            return (getRegionMeta(selectedLocation.sef).locationTitleDeclension !== '') ?
                (' v kraji ' + getRegionMeta(selectedLocation.sef).locationRegionMainCity) :
                (getEditedRegionName(selectedLocation.name, 'okres', ''));
        }
        return null;
    };

    const getLocationHeadMetaTitle = () => {
        return 'Novostavby' +
            (getSelectedLocationNameForMetaTitle(selectedLocation) ?
                getSelectedLocationNameForMetaTitle(selectedLocation) :
                ' a developerské projekty') + ' | ' + AppConfigService.getTitle();
    };

    const getLocationHeaderTitle = () => {
        if (!selectedLocation) {
            return '';
        }

        return getEditedRegionName(
            selectedLocation.name,
            'okres ',
            ''
        );
    };

    const getLocationHeadMetaDesc = () => {
        const descHelper = 'Pozrite si aktuálnu mapu developerských projektov. Všetky developerské projekty a novostavby na jednom mieste.';

        if (selectedLocation) {
            return (getRegionMeta(selectedLocation.sef).locationTitle !== '') ?
                ('Novostavby - ' + getRegionMeta(selectedLocation.sef).locationTitle) + '. ' + descHelper:
                (getEditedRegionName(selectedLocation.name, 'okres ', '')) + ' - novostavby. '
                + descHelper;
        }
        return 'Pozrite si aktuálnu mapu developerských projektov na Slovensku. Všetky developerské projekty a novostavby na jednom mieste.';
    };

    return {
        getLocationOptions,
        getLocationHeaderTitle,
        getLocationHeadMetaTitle,
        getLocationHeadMetaDesc,
    };
};