import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import NoItemsImage from 'components/page/search/map/svg/listing/noItemsSmall.svg';
import { Svg } from 'modules/theme/components/svg/Svg';


export const NoData: FC = () => {
    return <Box display='flex' justifyContent='center' flexDirection='column' width='100%'>

        <Box mb={2} width='100%' display='flex' justifyContent='center'>
            <Svg component={NoItemsImage} width={260} height={215} />
        </Box>

        <Text variant='h5' component='h1' semibold center mb={1}>
            Nenašli sa žiadne výsledky
        </Text>

        <Text variant='body2' component='h1' center mb={1}>
            Skúste si zmeniť alebo zrušiť niektoré parametre vo filtri.
        </Text>

    </Box>;
};


