import { FC } from 'react';
import { Box } from '@mui/material';
import { IOption } from 'modules/theme/components/fields/select/interfaces/IOption';
import { AutocompleteSelect } from 'modules/theme/components/fields/select/AutocompleteSelect';
import Field from '@uc-platform/advertisement-search-form/dist/es5/Components/Fields/Types/Field';
import useFieldContext from '@uc-platform/advertisement-search-form/dist/es5/Components/Hooks/useFieldContext';

interface IProps {
    label: string;
    fieldName: string;
    options: IOption[];
    testId?: string;
}

export const Autocomplete: FC<IProps> = ({ fieldName, label, options, testId }) => {
    const { value: currentValue, setValue } = useFieldContext(fieldName, Field.OPERATION_EQUAL);
    const selectedOption = options.find(option => option.value === currentValue)?.value || undefined;

    return(
        <Box component='span' data-test-id={testId}>
            <AutocompleteSelect
                label={label}
                options={options}
                selectedValue={selectedOption}
                onChange={(val) => {
                    setValue(val);
                }}
            />
        </Box>
    );

};
