import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

interface IBadgeLabel {
    children: ReactNode | ReactNode[];
}

export const BadgeLabel: FC<IBadgeLabel> = ({ children }) => {
    return (
        <Box mt={-0.625} mr={-.5}>
            {children}
        </Box>
    );
};
