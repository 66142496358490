import { FC } from 'react';
import { Paginator } from 'components/page/search/map/components/paginator/Paginator';

interface IPagination {
    isDisabled?: boolean;
    currentPage: number;
    totalPageCount: number;
    onChange: (newPage: number) => void;
}

export const Pagination: FC<IPagination> = ({ currentPage, totalPageCount, onChange, isDisabled }) => {
    return <Paginator
        onChange={onChange}
        isDisabled={isDisabled}
        currentPage={currentPage}
        totalPageCount={totalPageCount}
    />;
};
