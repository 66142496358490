'use client';

import dynamic from 'next/dynamic';
import { FC, useState } from 'react';
import { Grid, Box, useMediaQuery, useTheme } from '@mui/material';
import { Mobile } from 'modules/theme/components/responsive';
import { Filter } from 'components/page/search/map/components/filter/Filter';
import { Listing } from 'components/page/search/map/components/searchResult/listing/Listing';
import { MapListingSwitcher } from 'components/page/search/map/components/mapListingSwitcher/MapListingSwitcher';
import { State } from 'components/page/search/map/components/State';
import { usePageViewEvent } from 'modules/gtmEvents/hooks/pageView/usePageViewEvent';

const Map = dynamic(() => import('components/page/search/map/components/searchResult/map/Map'), { ssr: false });

interface IProps {
    defaultLocationId: string;
    locationTitle: string;
}

export const Main: FC<IProps> = ({ defaultLocationId, locationTitle }) => {
    const { palette, breakpoints, zIndex } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('md'));
    const [isMapShownOnMobile, setIsMapShownOnMobile] = useState<boolean>(true);

    usePageViewEvent();

    const showMap = !isMobile || isMapShownOnMobile;
    const showAdvertisementListing = !isMobile || !isMapShownOnMobile;

    return <>
        <Grid container spacing={0}>
            <State />
            <Grid item xs={12} boxShadow={{ md: '0px 4px 4px ' + palette.labelQuarternary.main }}>
                <Filter defaultLocationId={defaultLocationId} locationTitle={locationTitle}/>
            </Grid>

            {showAdvertisementListing &&
                <Grid
                    item
                    md={6}
                    lg={5}
                    xl={4}
                    px={3}
                    xs={12}
                    overflow={{ md: 'scroll' }}
                    height={{ md: '70vh', lg: '80vh' }}
                    display={isMobile ? isMapShownOnMobile ? 'none' : 'flex' : 'flex'}
                >
                    <Listing locationTitle={locationTitle}/>
                </Grid>
            }
            {showMap &&
                <Grid item xs={12} md={6} lg={7} xl={8} height={{ xs: '90vh', md: '70vh', lg: '80vh' }} data-test-id='map'>
                    <Map/>
                </Grid>
            }

        </Grid>
        <Mobile>
            <Box
                left='50%'
                bottom={35}
                display='flex'
                position='fixed'
                alignItems='center'
                justifyContent='center'
                zIndex={zIndex.mobileStepper}
                sx={{ transform: 'translateX(-50%)' }}
            >
                <MapListingSwitcher
                    isShowingMap={isMapShownOnMobile}
                    onClick={() => setIsMapShownOnMobile(current => !current)}
                />
            </Box>
        </Mobile>

    </>;
};
