import { FC } from 'react';
import { Box, useTheme } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import useSelectedItemId from 'modules/stores/page/search/useSelectedItemId';
import MapIcon from 'modules/theme/components/icons/basic/Map.svg';
import GridViewIcon from 'modules/theme/components/icons/basic/GridView.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IMapListingSwitcher {
    isShowingMap: boolean;
    onClick: () => void;
}

export const MapListingSwitcher: FC<IMapListingSwitcher> = ({ isShowingMap, onClick }) => {
    const { palette } = useTheme();
    const { setSelectedItemId } = useSelectedItemId();
    const { gtm: { sendEvent: gtmSendEvent }} = useAnalytics();

    const name = isShowingMap ? 'Zoznam' : 'Mapa';
    const gaEventAction = isShowingMap ? 'zoznam' : 'mapa';

    const handleOnClick = () => {
        onClick();
        setSelectedItemId('');
        gtmSendEvent({ event: 'track-event', category: 'mapa_projekty', action: gaEventAction, label: 'klik' });
    };

    return <Box
        zIndex={1}
        width={121}
        paddingY={1}
        paddingX={2}
        display='flex'
        borderRadius={5}
        alignItems='center'
        onClick={handleOnClick}
        sx={{ cursor: 'pointer' }}
        justifyContent='space-around'
        bgcolor={palette.backgroundPrimary.main}
        boxShadow={'0px 0px 15px ' + palette.labelQuarternary.main}
    >
        {isShowingMap ? <Svg icon component={GridViewIcon}/> : <Svg icon component={MapIcon} />}

        <Text variant='label1'>
            {name}
        </Text>
    </Box>;
};
