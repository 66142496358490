import { FC, ReactNode } from 'react';
import { Box, Theme } from '@mui/material';

interface IGradientWrapper {
    children: ReactNode;
    flexDirection: 'row' | 'column'
}

export const GradientWrapper: FC<IGradientWrapper> = ({ children, flexDirection }) => {
    return <Box
        left={0}
        pb={2.5}
        pt={3.5}
        px={1.75}
        bottom={0}
        width='100%'
        overflow='hidden'
        position='absolute'
        flexDirection={flexDirection}
        sx={{ backgroundImage: (theme: Theme) => `linear-gradient(180deg, transparent 0%, ${theme.palette.backgroundPrimaryInverse.main} 50%)` }}
    >
        {children}
    </Box>;
};
