import { FC } from 'react';
import formatPrice from 'modules/format/number';
import { Text } from 'modules/theme/components/text/Text';
import { EUnitPriceId } from 'components/page/search/map/enum/EUnitPriceId';


interface INumericalPrice {
    priceFrom: number;
    unitFrom?: number;
    unitUnitId?: EUnitPriceId;
    priceUnitId?: EUnitPriceId;
}

export const NumericalPrice: FC<INumericalPrice> = ({ priceFrom, unitFrom, unitUnitId, priceUnitId }) => {
    return <>
        <Text variant='body1' bold inverseColor>
            od {formatPrice(priceFrom, priceUnitId)}
        </Text>

        {unitFrom !== 0 &&
            <Text variant='footnote' inverseColor>
                od {formatPrice(unitFrom, unitUnitId)}
            </Text>
        }
    </>;
};
