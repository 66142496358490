import { IFormValue } from '@uc-platform/advertisement-search-form/dist/es5/Components/SearchForm/SearchFormInterfaces';
import { ECategories } from 'components/page/search/map/enum/ECategories';

const categoryIdPriority = [
    ECategories.BYTY,
    ECategories.DOMY,
    ECategories.POZEMKY,
    ECategories.CHATY_A_CHALUPY,
    ECategories.OBJEKTY,
    ECategories.PRIESTORY
];

const filterCategoryValues = (values: IFormValue[]): IFormValue[] => {
    const categories = values.filter(value => parseInt(value.parentId, 10) === 1 && categoryIdPriority.includes(+value.uniqueKey));
    const categoryListArray =[];

    for (const category of categories) {
        categoryIdPriority.includes(+category.uniqueKey);
        if (category.uniqueKey === ECategories.CHATY_A_CHALUPY.toString()) {
            category.name = 'Rekreačné domy';
        }
        categoryListArray[categoryIdPriority.indexOf(+category.uniqueKey)] = category;
    }

    return categoryListArray;
};

export default filterCategoryValues;