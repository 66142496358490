import React, { FC } from 'react';
import { Grid, Skeleton } from '@mui/material';


export const ItemSkeleton: FC = () => {
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Skeleton variant='rectangular' width='100%' height={140} animation='wave'/>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton width='30%' animation='wave'/>
                    <Skeleton width='50%' animation='wave'/>
                    <Skeleton width='50%' animation='wave'/>
                    <Skeleton width='50%' animation='wave'/>
                </Grid>
                <Grid item xs={12}>
                    <Skeleton width='100%' animation='wave'/>
                </Grid>
            </Grid>
        </>
    );
};

