import React, { FC } from 'react';
import { Box } from '@mui/material';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import { Text } from 'modules/theme/components/text/Text';

interface ILocationInfo {
    street?: string;
    city: string;
    district: string;
}

export const LocationInfo: FC<ILocationInfo> = ({ street, city, district }) => {
    return <Text variant='label2' component='p' noWrap inverseColor>
        <Box display='flex' gap={.5}>
            <LocationOnOutlined sx={{ width: 13, height: 13, position: 'relative', top: '2px' }}/>
            {(street && street !== '0') ? `${street}, ` : ''} {city}, okres {district}
        </Box>
    </Text>;

};


