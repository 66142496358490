import React, { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import { useForm } from 'components/page/search/map/hooks/filter/useForm';
import { ISearchFormPropsInterface } from '@uc-platform/advertisement-search-form/dist/es5/Components/SearchForm/SearchFormInterfaces';
import SearchForm from '@uc-platform/advertisement-search-form/dist/es5/Components/SearchForm/SearchForm';

interface IFormWrapper {
    children: JSX.Element
    defaultLocationId: string;
}

interface IFormProps extends ISearchFormPropsInterface {
    children: ReactNode | ReactNode[];
}

export const SearchFormTyped = styled(SearchForm)<IFormProps>``;

export const FormWrapper: FC<IFormWrapper> = ({ children, defaultLocationId }) => {
    const { onFormValuesChange } = useForm();

    return <SearchFormTyped
        lang='sk'
        onValuesChanged={(value) => onFormValuesChange({
            ...value.values.eq,
            allProjects: value.values.eq['description']
        })}
        project={AppConfigService.getPlatformProjectName()}
        apiEndpoint={AppConfigService.getPlatformParameterServiceUrl()}
        availableFields={['title', 'category', 'transaction', 'real_estate_state',]}
        searchFormData={{
            values: {
                lt: {},
                gt: {},
                neq: {},
                lte: {},
                gte: {},
                like: {},
                eq: { location_id: defaultLocationId },
            }
        }}
    >
        {children}
    </SearchFormTyped>;
};
