import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';

interface ITitle {
    title: string
    onMouseOut: () => void;
    onMouseOver: () => void;
}

export const Title: FC<ITitle> = ({ title, onMouseOver, onMouseOut }) => {

    return <Box onMouseOut={onMouseOut} onMouseOver={onMouseOver} mb={1}>
        <Text variant='h4' inverseColor bold noWrap>
            {title}
        </Text>
    </Box>;
};


