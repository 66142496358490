import { FC } from 'react';
import { Grid } from '@mui/material';
import { PAGE_SIZE } from 'modules/stores/page/search/useSearchPageStore';
import { Advertisement } from 'components/page/search/map/components/searchResult/advertisement/Advertisement';
import IItem from 'components/page/search/map/interfaces/IItem';

interface IAdvertisementListing {
    currentPage: number;
    items: IItem[];
}

export const AdvertisementsList: FC<IAdvertisementListing> = ({ currentPage, items }) => {
    const slicedListOfProjects = items.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

    return <Grid container spacing={2}>

        {slicedListOfProjects.map(item =>
            <Grid item xs={12} sm={item.services.isPremium ? 12 : 6} key={item.externalId} overflow='hidden' data-test-id='advertisementItem'>
                <Advertisement
                    isHoverable
                    item={item}
                    gtmEventData={{ event: 'track-event', category: 'mapa_projekty', action: 'detail-projektu-vypis', label: 'klik' }} />
            </Grid>
        )}

    </Grid>;
};
