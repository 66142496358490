import { FC } from 'react';
import { IconButton, Theme } from '@mui/material';
import FilterIcon from 'modules/theme/components/icons/basic/Filter.svg';
import { Badge } from 'components/page/search/map/components/filter/mobile/header/menuButton/partials/Badge';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IMenuButton {
    showBadge: boolean;
    onClick: () => void;
}

export const MenuButton: FC<IMenuButton> = ({ onClick, showBadge }) => {
    return <IconButton onClick={onClick} sx={{
        color: (theme: Theme) => theme.palette.labelPrimary.main
    }}>
        <Badge showBadge={showBadge}>
            <Svg icon component={FilterIcon} />
        </Badge>
    </IconButton>;
};
