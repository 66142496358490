import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';
import { Svg } from 'modules/theme/components/svg/Svg';

interface IHeadline {
    onCloseIconClick: () => void
}

export const Headline: FC<IHeadline> = ({ onCloseIconClick }) => {
    return <Box position='relative'>
        <Box position='absolute' left={0}>
            <IconButton onClick={onCloseIconClick} sx={{ bgcolor: 'inherit' }}>
                <Svg icon component={CloseIcon} />
            </IconButton>
        </Box>

        <Text variant='h4' semibold center>
            Filter
        </Text>
    </Box>;
};
