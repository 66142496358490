import { FC } from 'react';
import { Box, Palette } from '@mui/material';
import { Text } from 'modules/theme/components/text/Text';

interface IBadgeLabel {
    text: string;
    bgcolor: string
}

export const TextLabel: FC<IBadgeLabel> = ({ text, bgcolor }) => {
    return <Box borderRadius={1} py={.5} px={1} bgcolor={bgcolor}>
        <Text
            bold
            variant='label2'
            textTransform='uppercase'
            color={(palette: Palette) => palette.labelPrimaryInverse}
        >
            {text}
        </Text>
    </Box>;
};
